import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import ListGroup from "react-bootstrap/ListGroup";
import Image from 'react-bootstrap/Image';
import withLayout from "../hoc/withLayout";
import OgImage from "../images/og-image-okleika-plenkoi.jpg";
import ImageAbout from "../images/okleika-plenkoi.jpg";
import ImageAboutWebp from "../images/okleika-plenkoi.jpg.webp";
import HeaderImage from "../images/header-okleika-plenkoi.jpg";
import HeaderImageWebp from "../images/header-okleika-plenkoi.jpg.webp";
import ComplexImage from "../images/complex-okleika-plenkoi.jpg";
import ComplexImageWebp from "../images/complex-okleika-plenkoi.jpg.webp";
import ComplexImage1 from "../images/complex-okleika-plenkoi-1.jpg";
import ComplexImage1Webp from "../images/complex-okleika-plenkoi-1.jpg.webp";
import ComplexImage2 from "../images/complex-okleika-plenkoi-2.jpg";
import ComplexImage2Webp from "../images/complex-okleika-plenkoi-2.jpg.webp";
import ComplexImage3 from "../images/complex-okleika-plenkoi-3.jpg";
import ComplexImage3Webp from "../images/complex-okleika-plenkoi-3.jpg.webp";
import HeaderJumbotron from "../components/HeaderJumbotron";
import Yandex from "../components/Yandex";
import Footer from "../components/Footer";
import { Button, Ratio } from "react-bootstrap";
import ModalFormCallOrder from "../components/ModalFormCallOrder";
import "./okleika-plenkoi.scss";
import { Gallery } from "../components/Gallery";


const price = [
  {
    title: "Фары противотуманные (2 шт)",
    price: 40,
  },
  {
    title: "Передние фары (2 шт)",
    price: 120,
  },
  {
    title: "Передняя часть капота 40см",
    price: 240,
  },
  {
    title: "Защитная полоса на крылья (2 шт)",
    price: 100,
  },
  {
    title: "Защитная полоса на крышу передняя часть 25 см",
    price: 100,
  },
  {
    title: "Защитная оклейка зеркал (2 шт)",
    price: 100,
  },
  {
    title: "Пространство под дверными ручками (1 шт)",
    price: 10,
  },
  {
    title: "Кантик двери (1см)",
    price: 10,
  },
  {
    title: "Низ двери 15-20см",
    price: 100,
  },
  {
    title: "Порог внутренний (1 шт)",
    price: 25,
  },
  {
    title: "Зона выгрузки багажника",
    price: 100,
  },
  {
    title: "Стойки лобового стекла (2 шт)",
    price: 100,
  },
  {
    title: "Дверь полностью (1 шт)",
    price: 380,
  },
  {
    title: "Капот",
    price: 750,
  },
  {
    title: "Крыло переднее (1 шт)",
    price: 270,
  },
  {
    title: "Порог полностью (1 шт)",
    price: 250,
  },
  {
    title: "Бампер задний",
    price: 750,
  },
  {
    title: "Бампер передний",
    price: 700,
  },

]



const OkleikaPlenkoiPage = () => {
  const gallery = useStaticQuery(graphql`
    {
      allFile(
        filter: {extension: {in: ["jpg", "webp"]}, dir: {regex: "/\/src\/images\/okleika-plenkoi$/"}}
        sort: {fields: base, order: ASC}
      ) {
        edges {
          node {
            base
            publicURL
          }
        }
      }
    }
  `)

    return (
    <>
      <HeaderJumbotron 
        h1="Оклейка автомобиля антигравийной пленкой в Минске" 
        h1Sub="Наш профессионализм - ваша безопасность" 
        bgImg={HeaderImage} bgImgWebp={HeaderImageWebp}
        bgImgAlt="оклейка автомобиля плёнкой"
        withPhoneButton
        modalSubject="Оклейка плёнкой - заказ звонка"
        modalTitle="Оклейка плёнкой - заказ звонка"
      />
      
      <main>
        <div className="team">
          <div className="team__header">
            <Container>
              <p className='h1'>Autozorgo Detailing</p>
            </Container>
          </div>

          <div className='team__description'>
            <Container>
              <p>
                - команда профессионалов по установке различных видов пленок. 
                Мы дорожим своей репутацией, именно поэтому относимся к своей работе добросовестно 
                и <strong>даем гарантию</strong> на выполненные проекты, 
                придерживаемся <strong>приемлемой ценовой</strong> политики 
                и стараемся выполнить свою работу <strong>точно в срок</strong>
              </p>
            </Container>
          </div>
        </div>

        <div className="benefit">
          <Container>
            <p className='h1'>Не упустите шанс!</p>
            <p className="benefit__description">
              Закажите любой комплекс и 
              получите <span>оклейку мест под ручками <i>(4 шт.)</i></span> и {" "}
              <span>кантов дверей <i>(4 шт.)</i></span> <strong>стоимостью 80 BYN</strong>
            </p>
          </Container>
        </div>

        <div className="complex">
          <picture>
            <source srcSet={ComplexImageWebp} type="image/webp" />
            <source srcSet={ComplexImage} type="image/jpeg" />
            <img src={ComplexImage} className={"complex__bg"} alt="оклейка автомобиля антигравийной плёнкой" />
          </picture>
          <Container className="complex__header">
            <p className="h1">Комплекты</p>
            <p className="h5">
              Мы разработали для вас 3 оптимальных комплекта, 
              которые надёжно защитят ЛКП вашего автомобиля
            </p>
          </Container>

          <Container>
            <Row>
              <Col xs={12} sm={12} md={12} lg={4}>
                <Card className="complex__card mb-4">
                <picture>
                    <source srcSet={ComplexImage1Webp} type="image/webp" />
                    <source srcSet={ComplexImage1} type="image/jpeg" />
                    <img src={ComplexImage1} alt="оклейка автомобиля антигравийной плёнкой стандартный комплект" className="card-img-top"/>
                  </picture>
                  <Card.Body>
                    <Card.Title className="mb-4">Стандарт</Card.Title>
                  </Card.Body>

                  <ListGroup variant="flush">
                    <ListGroup.Item>передняя оптика (фары+птф)</ListGroup.Item>
                    <ListGroup.Item>капот частично</ListGroup.Item>
                    <ListGroup.Item>стойки лобового стекла</ListGroup.Item>
                    <ListGroup.Item>крылья частично</ListGroup.Item>
                    <ListGroup.Item>полоса над лобовым стеклом</ListGroup.Item>
                    <ListGroup.Item>подручки</ListGroup.Item>
                  </ListGroup>
                  <Card.Body>
                    <ModalFormCallOrder subject="Оклейка плёнкой - Стандарт" title="Оклейка плёнкой - Стандарт">
                      <Button variant={'primary'} size="lg" className="d-block">
                        Рассчитать стоимость
                      </Button>
                    </ModalFormCallOrder>
                  </Card.Body>
                </Card>
              </Col>

              <Col xs={12} sm={12} md={12} lg={4}>
                <Card className="complex__card complex__card_hit mb-4">
                  <picture>
                    <source srcSet={ComplexImage2Webp} type="image/webp" />
                    <source srcSet={ComplexImage2} type="image/jpeg" />
                    <img src={ComplexImage2} alt="оклейка автомобиля антигравийной плёнкой расширенный комплект" className="card-img-top"/>
                  </picture>
                  <Card.Body>
                    <Card.Title className="mb-4">Расширенный</Card.Title>
                  </Card.Body>
                  <ListGroup variant="flush">
                    <ListGroup.Item>передняя оптика (фары+птф)</ListGroup.Item>
                    <ListGroup.Item>капот частично</ListGroup.Item>
                    <ListGroup.Item>стойки лобового стекла</ListGroup.Item>
                    <ListGroup.Item>крылья частично</ListGroup.Item>
                    <ListGroup.Item>полоса над лобовым стеклом</ListGroup.Item>
                    <ListGroup.Item>подручки</ListGroup.Item>
                    <ListGroup.Item>боковые зеркала</ListGroup.Item>
                    <ListGroup.Item>пороги частично</ListGroup.Item>
                    <ListGroup.Item>передний бампер</ListGroup.Item>
                    <ListGroup.Item>кромки дверей</ListGroup.Item>
                  </ListGroup>
                  <Card.Body>
                    <ModalFormCallOrder subject="Оклейка плёнкой - Расширенный" title="Оклейка плёнкой - Расширенный">
                      <Button variant={'primary'} size="lg" className="d-block">
                        Рассчитать стоимость
                      </Button>
                    </ModalFormCallOrder>
                  </Card.Body>
                </Card>
              </Col>

              <Col xs={12} sm={12} md={12} lg={4}>
                <Card className="complex__card mb-4">
                  <picture>
                    <source srcSet={ComplexImage3Webp} type="image/webp" />
                    <source srcSet={ComplexImage3} type="image/jpeg" />
                    <img src={ComplexImage3} alt="оклейка автомобиля антигравийной плёнкой полный комплект" className="card-img-top"/>
                  </picture>
                  <Card.Body>
                    <Card.Title className="mb-4">Полный</Card.Title>
                  </Card.Body>
                  <ListGroup variant="flush">
                    <ListGroup.Item>передняя оптика (фары+птф)</ListGroup.Item>
                    <ListGroup.Item>капот цельно</ListGroup.Item>
                    <ListGroup.Item>стойки лобового стекла</ListGroup.Item>
                    <ListGroup.Item>крылья</ListGroup.Item>
                    <ListGroup.Item>полоса над лобовым стеклом</ListGroup.Item>
                    <ListGroup.Item>подручки</ListGroup.Item>
                    <ListGroup.Item>боковые зеркала</ListGroup.Item>
                    <ListGroup.Item>пороги</ListGroup.Item>
                    <ListGroup.Item>передний бампер</ListGroup.Item>
                    <ListGroup.Item>кромки дверей</ListGroup.Item>
                    <ListGroup.Item>полка заднего бампера</ListGroup.Item>
                  </ListGroup>
                  <Card.Body>
                    <ModalFormCallOrder subject="Оклейка плёнкой - Полный" title="Оклейка плёнкой - Полный">
                      <Button variant={'primary'} size="lg" className="d-block">
                        Рассчитать стоимость
                      </Button>
                    </ModalFormCallOrder>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>

        <Container className="service-add mb-4">
          <h2 className="text-center mb-4 mt-4">Дополнительные услуги</h2>
          <div className="service-add__card shadow-lg rounded-4">
            <ul>
              {
                price.map((val, idx) => {
                  return (
                    <li key={`price-${idx}`}>
                      <span className="a">
                        <span className="title">{val.title}</span>
                        <span className="price">от {val.price} BYN</span>
                      </span>
                    </li>
                  )
                })
              }
            </ul>
          </div>
        </Container>
              
        <Container className="about">
          <Row className="flex-column-reverse flex-lg-row">
            <Col xs={12} sm={12} md={12} lg={6}>
              <picture>
                <source srcSet={ImageAbout} type="image/jpeg" />
                <source srcSet={ImageAboutWebp} type="image/webp" />
                <Image src={ImageAbout} alt="оклейка фар антигравийной плёнкой" className="rounded-4 mb-5" fluid/>
              </picture>
            </Col>

            <Col xs={12} sm={12} md={12} lg={6} className="d-flex flex-column justify-content-center align-items-start">
              <h3>Антигравийная пленка защитит ваш автомобиль от</h3>
              <ul className="mb-4">
                <li>Гравия и камней, вылетающих из-под колес автомобилей</li>
                <li>Ультрафиолета</li>
                <li>Кустов и веток деревьев, расположенных во дворе вашего дома</li>
                <li>Химических реагентов, которыми  обрабатывают дороги</li>
              </ul>

              <ModalFormCallOrder subject="Оклейка плёнкой - заказ звонка" title="Оклейка плёнкой - заказ звонка">
                <Button variant={'primary'} className="mb-5">
                  Заказать звонок
                </Button>
              </ModalFormCallOrder>
            </Col>
          </Row>

          <Row>
            <Col xs={12}>
              <h2>Антигравийная защита автомобиля</h2>
              <p>
                Антигравийная защита автомобиля представляет собой покрытие элементов кузова 
                специальной полиуретановой пленкой (PPF), разработанной для защиты от 
                механических повреждений и агрессивных факторов окружающей среды.
              </p>
              <p>
                Полиуретановая пленка обладает рядом важных характеристик, которые обеспечивают 
                долговечную защиту лакокрасочного покрытия вашего автомобиля. 
                Эта пленка полностью прозрачна и устойчива к выцветанию благодаря своей способности 
                пропускать УФ-излучение, что также обеспечивает равномерное старение краски кузова.
              </p>
              <p>
                К тому же, полиуретановая пленка имеет свойство "самовосстановления", 
                что позволяет устранять мелкие царапины воздействием высоких температур.
                Дополнительный лаковый слой TOP COAT на поверхности пленки создает не только привлекательный блеск,
                но и обеспечивает гидрофобные свойства, отталкивая воду и грязь.
              </p>
              <p>
                Помимо защиты от механических повреждений, антигравийная пленка 
                также способна защитить автомобиль от воздействия химических веществ, таких как соли, 
                кислоты и другие агрессивные соединения, которые могут повредить лакокрасочное покрытие.
                Это особенно актуально в зимний период, когда дороги обрабатываются реагентами.
              </p>
            </Col>
          </Row>
        </Container>

        <Container className="mt-4">
          <div className="advantage shadow-lg rounded-4">
            <h3 className="text-center">Наши преимущества</h3>
            <div className="advantage__items">
              <div className="advantage__item">
                <svg xmlns="http://www.w3.org/2000/svg" width="165" height="165" viewBox="0 0 165 165" fill="none">
                  <path d="M82.5 35.4009V82.5009H129.6" stroke="#29C4BE" strokeWidth="8" strokeLinecap="round" strokeLinejoin="round"/>
                  <path d="M82.5 161C125.854 161 161 125.854 161 82.5C161 39.1456 125.854 4 82.5 4C39.1456 4 4 39.1456 4 82.5C4 125.854 39.1456 161 82.5 161Z" stroke="#29C4BE" strokeWidth="8" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
                <span>Точно в срок</span>
              </div>

              <div className="advantage__item">
                <svg xmlns="http://www.w3.org/2000/svg" width="158" height="165" viewBox="0 0 158 165" fill="none">
                  <path d="M75.7434 5.17724C77.5503 3.60759 80.2371 3.60759 82.0441 5.17724L97.6421 18.7282C98.6252 19.5821 99.9126 20.0004 101.21 19.8874L121.794 18.0929C124.179 17.885 126.352 19.4643 126.892 21.7963L131.546 41.9278C131.84 43.1963 132.634 44.2914 133.751 44.9624L151.459 55.6099C153.51 56.8433 154.34 59.3986 153.405 61.6021L145.338 80.6242C144.83 81.8235 144.83 83.1765 145.338 84.3758L153.405 103.398C154.34 105.601 153.51 108.157 151.459 109.39L133.751 120.038C132.634 120.708 131.84 121.804 131.546 123.073L126.892 143.204C126.352 145.536 124.179 147.115 121.794 146.907L101.21 145.113C99.9126 144.999 98.6252 145.418 97.6421 146.271L82.0441 159.823C80.2371 161.392 77.5503 161.392 75.7434 159.823L60.1451 146.271C59.1622 145.418 57.8747 144.999 56.5776 145.113L35.9932 146.907C33.6088 147.115 31.4351 145.536 30.896 143.204L26.2416 123.073C25.9484 121.804 25.1526 120.708 24.0368 120.038L6.32897 109.39C4.27768 108.157 3.44738 105.601 4.38192 103.398L12.4495 84.3758C12.9578 83.1765 12.9578 81.8235 12.4495 80.6242L4.38192 61.6021C3.44738 59.3986 4.27768 56.8433 6.32897 55.6099L24.0368 44.9624C25.1526 44.2914 25.9484 43.1963 26.2416 41.9278L30.896 21.7963C31.4351 19.4643 33.6088 17.885 35.9932 18.0929L56.5776 19.8874C57.8747 20.0004 59.1622 19.5821 60.1451 18.7282L75.7434 5.17724Z" stroke="#29C4BE" strokeWidth="8"/>
                  <path d="M54.8525 90.5042L70.8644 106.516L110.894 66.4863" stroke="#29C4BE" strokeWidth="8" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
                <span>Официальная гарантия</span>
              </div>

              <div className="advantage__item">
                <svg xmlns="http://www.w3.org/2000/svg" width="166" height="165" viewBox="0 0 166 165" fill="none">
                  <path d="M53.0649 72.687L82.5024 102.125L161.002 23.6245" stroke="#29C4BE" strokeWidth="8" strokeLinecap="round" strokeLinejoin="round"/>
                  <path d="M161 82.5C161 125.855 125.855 161 82.5 161C39.1456 161 4 125.855 4 82.5C4 39.1456 39.1456 4 82.5 4C91.7954 4 100.713 5.61553 108.988 8.58106" stroke="#29C4BE" strokeWidth="8" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
                <span>Качественная пленка</span>
              </div>
              
              <div className="advantage__item">
                <svg xmlns="http://www.w3.org/2000/svg" width="165" height="165" viewBox="0 0 165 165" fill="none">
                  <path d="M82.5 4C39.1456 4 4 39.1456 4 82.5C4 125.854 39.1456 161 82.5 161C125.854 161 161 125.854 161 82.5C161 39.1456 125.854 4 82.5 4Z" stroke="#29C4BE" strokeWidth="8" strokeLinecap="round" strokeLinejoin="round"/>
                  <path d="M21.8423 132.315C21.8423 132.315 39.3401 109.976 82.5149 109.976C125.69 109.976 143.188 132.315 143.188 132.315" stroke="#29C4BE" strokeWidth="8" strokeLinecap="round" strokeLinejoin="round"/>
                  <path d="M82.5031 82.5009C95.5098 82.5009 106.053 71.9575 106.053 58.9509C106.053 45.9446 95.5098 35.4009 82.5031 35.4009C69.4965 35.4009 58.9531 45.9446 58.9531 58.9509C58.9531 71.9575 69.4965 82.5009 82.5031 82.5009Z" stroke="#29C4BE" strokeWidth="8" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
                <span>Сертифицированные специалисты</span>
              </div>
            </div>
          </div>
        </Container>

        <Container className="mt-4">
          <Gallery images={gallery.allFile.edges.map(({node}) => node.publicURL)} />
        </Container>
        
        <Container className="mt-4">
          <Ratio aspectRatio="16x9">
            <iframe src="https://www.youtube.com/embed/FniNiufIQjQ"
              title="YouTube video player"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          </Ratio>
        </Container>
        
        <Yandex/>
      </main>
      <Footer/>
    </>
    )
  }



export default withLayout(OkleikaPlenkoiPage,
    "Оклейка автомобиля антигравийной плёнкой в Минске - студия детейлинга AutoZorgo",
    "Официальная гарантия. Бронирование оптики, защита от сколов и царапин, химических реагентов",
    OgImage
  )